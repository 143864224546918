import { observable, action, computed, makeObservable } from 'mobx';
import { getText } from '../../../../../i18n';

import type {
  ReportsType,
  ReportForCreateType,
  PrepaymentsType,
  ExpensesType,
  SettingsExpenseType,
  ItemTypes,
  ItemsType,
} from '../types';
import { DocumentsType } from '../types';

import { countDailyExpenses } from '../../../utils/expenseReport';
import { formatDate, normalizeOurClusterLocalTime } from '../../../utils/formatDate';

import {
  PREPAYMENTS,
  DAILY_EXPENSES,
  STATUS_PAGE,
  DIALOGS,
  LOADINGS,
  EXPENSES_NAMES,
  PREFILED_VALUE_TYPE,
} from '../../../constants/expenseReport';
import { PATTERN } from '../../../constants/dateFormats';
import SERVICETYPE from '../../../constants/serviceType';

const LABELS = {
  NAME: getText('services:expenseReport.name'),
  TAXI: getText('expenseReports:report.serviceTypes.taxi'),
};

const DEFAULT_REPORT = {
  Id: 0,
  Name: '',
  CheckinDate: '',
  CheckoutDate: '',
  Stamp: '',
  Updated: false,
  Status: 0,
  CompanyId: 0,
  Data: {
    Employee: {
      Number: '',
      Surname: '',
      Name: '',
      Patronymic: '',
    },
    CompanyName: '',
    Expenses: [],
    TotalExpensesAmount: 0,
    Prepayments: [],
    TotalPrepaymentsAmount: 0,
    Balance: 0,
  },
  ImageLinks: [],
  Approves: [],
  Items: [],
  IsEditingAvailable: true,
  IsRemoveAvailable: true,
};

const DEFAULT_SETTINGS = {
  RuDailyExpenses: DAILY_EXPENSES.RU,
  OverseasDailyExpenses: DAILY_EXPENSES.NOT_RU,
  Rewritable: true,
};

export class ExpenseReportSingleStore {
  constructor() {
    makeObservable(this);
  }

  @observable report: ReportsType = DEFAULT_REPORT;
  @observable loadings: any = LOADINGS;
  @observable edit = false;
  @observable isValid = false;
  @observable isIntegration1S = false;
  @observable forcedPrompt = false;
  @observable rewritable = false;
  @observable showDeleteDocumentsItemDialog = false;
  @observable employee: any = {};
  @observable settings: SettingsExpenseType = DEFAULT_SETTINGS;
  @observable statusPage: string = STATUS_PAGE.CREATE;
  @observable dialogs: any = DIALOGS;
  @observable reportId: number | null = null;
  @observable stepsResolutions: string = '';
  @observable docItemIndex: null | number = null;
  @observable prefiledValue: ItemTypes[] = EXPENSES_NAMES;
  @observable prefiledValueType = PREFILED_VALUE_TYPE.NONE;

  @action
  setLoading = (value: boolean, type: string): void => {
    this.loadings = {
      ...this.loadings,
      [type]: value,
    };
  };

  @action
  setDialog = (value: boolean, type: string): void => {
    this.dialogs = {
      ...this.dialogs,
      [type]: value,
    };
  };

  @action
  setStatusPage = (status: string): void => {
    this.statusPage = status;
  };

  @action
  updateForcedPrompt = (value: boolean): void => {
    this.forcedPrompt = value;
  };

  @action
  setSettings = (settings: SettingsExpenseType): void => {
    this.settings = settings;

    if (!this.edit) {
      const { CheckinDate, CheckoutDate, Items, Data: { Prepayments } } = this.report;

      const prepayments = Prepayments.map((item) => ({
        ...item,
        Amount: item.Type === PREPAYMENTS.DAILY_EXPENSES ?
          countDailyExpenses(CheckinDate, CheckoutDate, Items, settings) :
          item.Amount,
      }));

      this.report = {
        ...this.report,
        Data: {
          ...this.report.Data,
          Prepayments: prepayments,
        },
      };
    }
  };

  @action
  setReport = (report: ReportsType, isIntegration1S: boolean): void => {
    this.report = {
      ...this.report,
      ...report,
      Stamp: normalizeOurClusterLocalTime(report.Stamp),
    };
    this.edit = true;
    this.isIntegration1S = isIntegration1S;
    this.forcedPrompt = true;
  };

  @action
  setNewReport = (report: ReportForCreateType, isIntegration1S: boolean, employee: any, companyId: number): void => {
    const { CheckinDate, CheckoutDate, Items: trips } = report;

    const Items = trips.reduce((r: ItemsType[], { TripItems }) => (
      [...r, ...TripItems]
    ), []);

    const prepayments = [{
      Method: 0,
      Type: 0,
      Amount: countDailyExpenses(CheckinDate, CheckoutDate, Items, this.settings),
    }];

    const { EmployeeNumber, Name, Surname, Patronymic, Id, Email } = employee;

    const foundCompany = employee.Companies.find(({ CompanyId }: { CompanyId: number | string }) => CompanyId === companyId);
    const companyName = foundCompany?.CompanyName || '';

    const name = () => {
      if (!!Items.length && Items[0].Name) {
        const finderNoTaxi = Items.find(({ ServiceType }) => ServiceType !== SERVICETYPE.TAXI_VOUCHER);

        if (Items[0].ServiceType === SERVICETYPE.TAXI_VOUCHER && finderNoTaxi) {
          return finderNoTaxi.Name;
        }

        return Items[0].Name;
      }

      return LABELS.NAME;
    };

    this.report = {
      ...this.report,
      ...report,
      Name: name(),
      CompanyId: companyId,
      Stamp: new Date(),
      Items,
      Data: {
        ...this.report.Data,
        CompanyName: companyName,
        Prepayments: prepayments,
        Employee: {
          ...this.report.Data.Employee,
          Number: EmployeeNumber,
          Surname,
          Name,
          Patronymic,
          Id,
          Email,
        },
      },
    };
    this.edit = false;
    this.isIntegration1S = isIntegration1S;
    this.isValid = true;
    this.employee = employee;
    this.forcedPrompt = true;
  };

  @action
  setPrepayment = (prepayment: PrepaymentsType, index: null | number): void => {
    const newPrepayments = index ? (() => {
      const copy = [...this.report.Data.Prepayments];
      copy[index] = { ...prepayment };

      return copy;
    })() : [
      ...this.report.Data.Prepayments,
      { ...prepayment },
    ];

    this.report = {
      ...this.report,
      Data: {
        ...this.report.Data,
        Prepayments: newPrepayments,
      },
    };

    this.isValid = true;
  };

  @action
  setPrepaymentDailyExpenses = (prepayment: PrepaymentsType): void => {
    const { Prepayments } = this.report.Data;
    const foundDailyExpenses = Prepayments.find(({ Type }) => Type === PREPAYMENTS.DAILY_EXPENSES);
    const { Amount, Method } = prepayment;
    const isValid = foundDailyExpenses && (foundDailyExpenses.Amount !== Amount || foundDailyExpenses.Method !== Method);

    if (isValid) {
      const prepayments = Prepayments.map((item) => {
        if (item.Type === PREPAYMENTS.DAILY_EXPENSES) {
          return {
            ...prepayment,
          };
        }

        return {
          ...item,
        };
      });

      this.report = {
        ...this.report,
        Data: {
          ...this.report.Data,
          Prepayments: prepayments,
        },
      };

      this.isValid = true;
    }
  };

  @action
  deletePrepaymentsItem = (index: number): void => {
    const prepayments = this.report.Data.Prepayments.reduce((acc: PrepaymentsType[], item: PrepaymentsType, ind: number) => {
      if (index === ind) {
        return acc;
      }

      return [...acc, item];
    }, []);

    this.report = {
      ...this.report,
      Data: {
        ...this.report.Data,
        Prepayments: prepayments,
      },
    };

    this.isValid = true;
  };

  @action
  setExpense = (expense: ExpensesType, ind: number | null | undefined): void => {
    const newExpenses = typeof ind === 'number' && !isNaN(ind) ? (() => {
      const copy = [...this.report.Data.Expenses];
      copy[ind] = { ...expense };

      return copy;
    })() : [
      ...this.report.Data.Expenses,
      { ...expense },
    ];

    this.report = {
      ...this.report,
      Data: {
        ...this.report.Data,
        Expenses: newExpenses,
      },
    };

    this.isValid = true;
  };

  @action
  setDocItemIndex = (index: number | null): void => {
    this.docItemIndex = index;
  };

  @action
  setDeleteDocumentsItemDialog = (value: boolean): void => {
    this.showDeleteDocumentsItemDialog = value;
  };

  @action
  deleteExpensesItem = (index: number): void => {
    const expenses = this.report.Data.Expenses.reduce((acc:ExpensesType[], item:ExpensesType, ind:number) => {
      if (index === ind) {
        return acc;
      }

      return [...acc, item];
    }, []);

    this.report = {
      ...this.report,
      Data: {
        ...this.report.Data,
        Expenses: expenses,
      },
    };

    this.isValid = true;
  };

  @action
  deleteDocumentsItem = (indexItemToDelete: number | null): void => {
    const documents = this.report.ImageLinks.reduce((acc: DocumentsType[], item: DocumentsType, ind: number) => {
      if (indexItemToDelete === ind) {
        return acc;
      }

      return [...acc, item];
    }, []);

    this.report = {
      ...this.report,
      ImageLinks: documents,
    };

    this.isValid = true;
  };

  @action
  setDocument = (document: DocumentsType, docIndex: null | number): void => {
    const newDocument = docIndex || docIndex === 0 ? (() => {
      const copy = [...this.report.ImageLinks];
      copy[docIndex] = { ...document };

      return copy;
    })() : [
      ...this.report.ImageLinks,
      { ...document },
    ];

    this.report = {
      ...this.report,
      ImageLinks: newDocument,
    };

    this.isValid = true;
  };

  @action
  setReportId = (id: number) => {
    this.reportId = id;
  };

  @action
  reset = (): void => {
    this.report = DEFAULT_REPORT;
    this.loadings = LOADINGS;
    this.edit = false;
    this.isValid = false;
    this.isIntegration1S = false;
    this.forcedPrompt = false;
    this.rewritable = false;
    this.employee = {};
    this.settings = DEFAULT_SETTINGS;
    this.statusPage = STATUS_PAGE.CREATE;
    this.reportId = null;
  };

  @action
  setApproveResolutions = (stepsResolution: string) => {
    this.stepsResolutions = stepsResolution;
  };

  @action
  setValidationDownloadDoc = (value: string, index: number) => {
    const mapperImageLinks = this.report.ImageLinks.map((item, ind) => {
      if (index !== ind) return item;

      return {
        ...item,
        ValidationDownloadDoc: value,
      };
    });

    this.report = {
      ...this.report,
      ImageLinks: mapperImageLinks,
    };
  };

  @action
  setPrefiledValue = (data: { type: string, values: ItemTypes[] }) => {
    this.prefiledValue = data.values;
    this.prefiledValueType = data.type;
  };

  @computed
  get isDailyExpenses(): boolean {
    return this.report.Data.Prepayments.some(({ Type }) => Type === PREPAYMENTS.DAILY_EXPENSES);
  }

  @computed
  get dataForSave(): any {
    const {
      Id,
      Name,
      CheckinDate,
      CheckoutDate,
      CompanyId,
      Status,
      ImageLinks,
      Data: { CompanyName, Employee, Expenses, Prepayments },
      EmployeeId,
      Items,
    } = this.report;

    const prepareImageLink = ImageLinks.map(({ EventDate, FileGuid, FileName, FileSize }) => ({
      EventDate,
      FileGuid,
      FileName,
      FileSize,
    }));

    return {
      Id,
      Status,
      Name,
      CheckinDate: formatDate(CheckinDate, PATTERN.YEAR_MONTH_DAY_TIME),
      CheckoutDate: formatDate(CheckoutDate, PATTERN.YEAR_MONTH_DAY_TIME),
      CompanyId,
      Data: {
        CompanyName,
        Employee,
        Expenses,
        Prepayments,
      },
      Items,
      EmployeeId,
      ImageLinks: prepareImageLink,
    };
  }
}

export const expenseReportStore = new ExpenseReportSingleStore();
